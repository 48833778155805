import React, { useState, useEffect } from 'react';
import { Typography, Button, } from '@mui/material';
import { BsTrash3 } from "react-icons/bs";

function DeleteButtonPopup({ product, handleRemoveFromCart }) {
	const [isTextBoxOpen, setIsTextBoxOpen] = useState(false);
    

    const wrapperHandleRemoveFromCart = (item) => {
		if (item.quantity === 1 & isTextBoxOpen === false) { 
			setIsTextBoxOpen(true);
		} else if (item.quantity === 1 & isTextBoxOpen === true) {
			setIsTextBoxOpen(false);
			handleRemoveFromCart(item.id);
		}
	}
	useEffect(() => {
        const handleClickOutside = (event) => {
            if (!event.target.closest(`.popup${product.id}`)) {
                setIsTextBoxOpen(false);
            }
        };

        document.body.addEventListener('click', handleClickOutside);

        return () => {
            document.body.removeEventListener('click', handleClickOutside);
        };
    }, [product.id, setIsTextBoxOpen]);
    return (
        <>
        <Button
            onClick={(product.quantity === 1) ? () => {wrapperHandleRemoveFromCart(product)} : () => {handleRemoveFromCart(product.id)}}
            className={`popup${product.id}`}
            sx={{ 
                color: (product.quantity === 1) ? '#721E1D' : 'white',
                padding: '0', 
                margin:'0', 
                width:{xs:"0", sm:'33%',}, 
                minWidth:{xs:"0", sm:'33%',},
                display:{xs:"none", sm:'inline-flex',},
                borderRadius: '0',
                borderTopLeftRadius: '5px',
                borderBottomLeftRadius: '5px',
                border: '1px solid #721E1D',
                borderRight: 'none',
                backgroundColor: (product.quantity === 1) ? '#721E1D' : 'transparent',
                '&:hover': {
                    backgroundColor: (product.quantity === 1) ? '#5f1c1a' : 'transparent',
                },
                position:'relative',
            }}
        >
            {(product.quantity === 1) ? <>{'\u200B'}<BsTrash3 style={{padding:'auto', height:'100%', color:'white'}}/></> : '-'}
        </Button>
        {isTextBoxOpen && (
            <div className={`popup${product.id}`} style={{ position: 'absolute', top: '100%', left: '17%', transform: 'translate(-50%, 0)', backgroundColor: '#0B0A0A', padding: '10px', borderRadius: '0', boxShadow: '0 2px 4px rgba(0, 0, 0, 0.2)', zIndex:2, width:'auto', border:'1px solid white' }}>
                <Typography variant="p" width="100%" textAlign="center" fontFamily="Poppins" sx={{ whiteSpace: 'nowrap' }}>Click again to delete from cart</Typography>
            </div>
        )}
        </>
    );
}

export default DeleteButtonPopup;