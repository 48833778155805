import React, { useState, useEffect } from 'react';
import { Typography, Grid, Box } from '@mui/material';
import Draggable from './Draggable';

function About() {
	const [activeDraggables, setActiveDraggables] = useState([1, 2, 3, 4]);
	const [lock, setLock] = useState(true);
	const handleDraggable = (id) => {
		setActiveDraggables(activeDraggables.filter((activeDraggable) => activeDraggable !== id));
	}

	useEffect (() => {
		if (activeDraggables.length === 0) {
			setLock(false);
		}
	}, [activeDraggables]);
	return (
		<Box position={'relative'} height={lock ? '95vh' : 'auto'} borderTop='1px solid white'>
			{lock ? 
				<>
					<Draggable handleDraggable={handleDraggable} id={1}/>
					<Draggable handleDraggable={handleDraggable} id={2}/>
					<Draggable handleDraggable={handleDraggable} id={3}/>
					<Draggable handleDraggable={handleDraggable} id={4}/>
				</>
			: 
				<Grid container>
					<Grid item xs={4} sx={{
						border: '1px solid white',
					}}>
						<img src='/videos/about_gif.gif' alt='about_gif' style={{ width: '90%', height: '90%', margin:'5%' }}/>
					</Grid>
					<Grid item xs={8} sx={{
						borderTop: '1px solid white',
						borderRight: '1px solid white',
						borderBottom: '1px solid white',
					}}>
						<Typography variant='h6' sx={{ textAlign: 'center', padding: '10%', color:'white', letterSpacing: 0.44, fontSize:'2vw', fontWeight:'700', width:'80%' }}>
						SILVER RIOT IS SILVER RIOT IS SILVER RIOT IS SILVER RIOT IS SILVER RIOT IS SILVER RIOT IS SILVER RIOT IS SILVER RIOT IS SILVER RIOT IS SILVER RIOT IS SILVER RIOT IS SILVER RIOT IS SILVER RIOT IS SILVER RIOT IS SILVER RIOT IS SILVER RIOT IS SILVER RIOT IS SILVER RIOT IS SILVER RIOT IS SILVER RIOT IS SILVER RIOT IS SILVER RIOT IS SILVER RIOT IS SILVER RIOT IS SILVER RIOT IS SILVER RIOT IS SILVER RIOT IS SILVER RIOT IS SILVER RIOT IS SILVER RIOT IS SILVER RIOT IS SILVER RIOT IS SILVER RIOT IS SILVER RIOT IS SILVER RIOT IS SILVER RIOT IS SILVER RIOT IS SILVER RIOT IS SILVER RIOT IS SILVER RIOT IS SILVER RIOT IS SILVER RIOT IS SILVER RIOT IS SILVER RIOT IS SILVER RIOT IS SILVER RIOT IS 
						</Typography>
					</Grid>
					<Grid item xs={9} sx={{
						padding: '15%',
						paddingRight: '10%',
					}}>
						<Typography variant='h4' color='white' paddingBottom='30px'>
							Company Brief:
						</Typography>
						<Typography variant='h6' color='white'>
							”Silver Riot is the concept of three engineers looking to shake up the  jewellery scene with fresh, unconventional designs and a bold marketing strategy. We craft jewellery that’s perfect for any situation—you can wear  our pieces anytime, anywhere, without worrying about them. They're tough enough to handle a wipe-out on a skateboard or a snowy backflip gone wrong, and stylish enough for a night out in Shoreditch or Soho. Curious about what makes us different? Grab one of our rings or bracelets and see for yourself”.
						</Typography>
					</Grid>
					<Grid item xs={3} sx={{
					}}>
						<img src='/images/about_us.png' alt='about_gif' style={{ width: '100%', height:'100%', borderLeft:'1px solid white', borderRight:'1px solid white', overflow:'hidden'}}/>
					</Grid>
					<Grid item xs={12} sx={{
					}}>
						<img src='/videos/about_gif_horizontal.gif' alt='about_gif' style={{ width:'100%', borderTop:'1px solid white'}}/>

					</Grid>
				</Grid>
			}
			{lock ? 
				<></>
			 : 
				<img src='/images/about_us_ghost.png' alt='about_us_ghost' style={{ position: 'absolute', top: '30%', right: '50%', width: '75%', height: '20%', transform:'translateX(50%)', zIndex:'-1' }}/>
			}
		</Box>
	);
}

export default About;
