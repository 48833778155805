import React, { useState } from 'react';
import Box from '@mui/material/Box';

function HoverableImg({src, alt, height, width}) {
    const [transformOrigin, setTransformOrigin] = useState('50% 50%');
    const [isHovered, setIsHovered] = useState(false);
    
    const handleMouseMove = (event) => {
        setIsHovered(true);
        const rect = event.target.getBoundingClientRect();
        const offsetX = event.clientX - rect.left;
        const offsetY = event.clientY - rect.top;
        const originX = (offsetX / rect.width) * 100;
        const originY = (offsetY / rect.height) * 100;
        setTransformOrigin(`${originX}% ${originY}%`);
    };

    

    const handleMouseLeave = () => {
        setIsHovered(false);
        setTransformOrigin('50% 50%');
    };
    return (
        <Box sx={{ width: `calc(${width} - 2px)`, height:`calc(${height} - 2px)`, border:'1px solid white', overflow:'hidden'}} onMouseMove={handleMouseMove} onMouseLeave={handleMouseLeave}>
            <img src={src} alt={alt} style={{
                display: 'block', 
                transition: 'transform 0.3s ease', 
                transformOrigin: transformOrigin, 
                transform: isHovered ? 'scale(1.2)' : 'scale(1)',
                width: '100%',
                height: '100%',
            }}/>
        </Box>
    );
}

export default HoverableImg;