import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Switch } from '@mui/material';

const Staff = () => {

    const filePath =
  		process.env.NODE_ENV === 'production'
			? 'https://silverriot.co.uk/api/'
			: 'http://127.0.0.1/api/';

    const [orders, setOrders] = useState([]);

    const handleToggle = (index, field) => {
        setOrders(prevOrders => {
            const updatedOrders = [...prevOrders];
            updatedOrders[index][field] = !updatedOrders[index][field];
            return updatedOrders;
        });
    };

    useEffect(() => {
		const getOrders = async () => {
			try {
				const response = await axios.get(filePath + "get_order.php", {
					method: 'GET',
                    credentials: 'include',
                });
                if (response.data.success) { 
                    console.log(response.data)
                } else {
                    console.log(response)
                }
				setOrders(response.data.orders);
			} catch (error) {
				console.error(error);
			}
		};
        console.log('staff.js useEffect called');
        getOrders();
        
    }, [filePath]);

    useEffect(() => {
        console.log(orders);
    }, [orders]);

    return (
        <TableContainer>
            <Table>
                <TableHead>
                    <TableRow>
                        <TableCell sx={{ color: 'white' }}>Order Id</TableCell>
                        <TableCell sx={{ color: 'white' }}>Name</TableCell>
                        <TableCell sx={{ color: 'white' }}>Name</TableCell>
                        <TableCell sx={{ color: 'white' }}>Made</TableCell>
                        <TableCell sx={{ color: 'white' }}>Shipped</TableCell>
                        <TableCell sx={{ color: 'white' }}>Arrived</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {orders?.map((order, index) => (
                        <TableRow key={index}>
                            <TableCell sx={{ color: 'white' }}>{order.order_id}</TableCell>
                            <TableCell sx={{ color: 'white' }}>{order.first_name}</TableCell>
                            <TableCell>
                                <Switch
                                    checked={order.made}
                                    onChange={() => handleToggle(index, 'made')}
                                />
                            </TableCell>
                            <TableCell>
                                <Switch
                                    checked={order.shipped}
                                    onChange={() => handleToggle(index, 'shipped')}
                                />
                            </TableCell>
                            <TableCell>
                                <Switch
                                    checked={order.arrived}
                                    onChange={() => handleToggle(index, 'arrived')}
                                />
                            </TableCell>
                        </TableRow>
                    ))}
                </TableBody>
            </Table>
        </TableContainer>
    );
};

export default Staff;