import React, { useState, useEffect, useRef } from 'react';
import { Box, Typography, Switch } from '@mui/material';
import LatestRelease from './LatestRelease';
// import TestColours from '../components/TestColours';
import CuratedContent from './CuratedContent';
const Home = () => {
    const videoRef = useRef(null);
    const [accessibilityState, setAccessibilityState] = useState(true); // when true video plays
    const handleChange = (event) => {
        setAccessibilityState(!accessibilityState);
    };
    useEffect(() => {
        if (accessibilityState) {
            playVideo();
        } else {
            pauseVideo();
        }
    },[accessibilityState]);
    const playVideo = () => {
        if (videoRef.current) {
            videoRef.current.play();
        }
    };
    const pauseVideo = () => {
        if (videoRef.current) {
            videoRef.current.pause();
        }
    };
    return (
        <div style={{
            position: 'relative',
        }}>
            {/* <TestColours /> */}
            <Box component="video" autoPlay muted loop preload="auto"
            sx={{ 
                width: '100%', 
                // minHeight: '740px', 
                height:'90vh',
                objectFit: 'cover',
                margin: '0 0 -4px 0',
            }} ref={videoRef}>
                <source src="/videos/Ski_promo_silver_riot_v2.mp4" type="video/mp4" />
            </Box>
            <Box sx={{
                position: 'absolute',
                top: '100px',
                right: '50px',
                transform: 'translate(-50%, -50%)',
                backgroundColor: 'rgba(0, 0, 0, 0.5)',
            }}>
                <Switch checked={accessibilityState} onChange={handleChange} color="primary" />
            </Box>
            <LatestRelease accessibilityState={accessibilityState} />
            <Box sx={{ 
                bgcolor: '#0B0A0A', 
                paddingY: '8px',
                borderTop: '1px solid white',
                borderBottom: '1px solid white',
            }}>
                <Typography variant="h5" sx={{color:'white', textAlign: 'center', fontFamily: 'Inter, sans-serif', fontWeight:'200', letterSpacing: '.05em', borderBottom: '1px solid white', paddingBottom:'8px'}}>
                    CURATED CONTENT
                </Typography>
                <CuratedContent accessibilityState={accessibilityState} />
            </Box>
            {/*<Grid container spacing={0} sx={{ backgroundColor:'#721e1d', borderBottom: '1px solid white', padding:'20px'}}>
                <Grid item xs={6}  sx={{ display: 'flex', alignItems: 'center', justifyContent:'center' }}>
                    <Typography variant="body2" 
                        sx={{ 
                            color: 'white', 
                            fontFamily: 'Inter, sans-serif', 
                            fontWeight: '200',
                            fontSize: { xs: '2.5vw' , md: '1.5vw' },
                        }}>
                        Subscribe to receive special offers and first glances at new products.
                    </Typography>
                </Grid>
                <Grid item xs={6}  sx={{ 
                    paddingX: '20px',
                    
                }}>
                    <Grid container spacing={1}>
                        <Grid item xs={12} md={8} sx={{
                            display: 'flex', 
                            alignItems: 'center', 
                            justifyContent: 'center'
                        }}>
                            <TextField
                                fullWidth
                                variant="outlined"
                                defaultValue={'Your Email (required)'}
                                InputProps={{
                                    style: {
                                        color: 'white',
                                        fontWeight: '200' ,
                                        fontFamily: 'Raleway, sans-serif',
                                        height: '40px',
                                    },
                                }}
                                sx={{
                                    '& .MuiOutlinedInput-root': {
                                        borderRadius: '50px',
                                        backgroundColor: 'rgba(255, 255, 255, 0.2);',
                                        borderColor: 'hsla(0,0%,100%,.09)',
                                    },
                                }}/>
                        </Grid>
                        <Grid item xs={12} md={4} 
                            sx={{ 
                                paddingX: '20px', 
                                display: 'flex', 
                                alignItems: 'center', 
                                justifyContent: { xs: 'center' , md: 'flex-start' },
                            }}>

                            <Button variant="contained" sx={{
                                color: 'white',
                                backgroundColor: 'black',
                                borderRadius: '50px',
                                width:'shrink',
                                textTransform: 'uppercase',
                            }}>
                                Sign up
                            </Button>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid> */}
            

        </div>
    );
};

export default Home;
