import React from 'react';
import { Link } from 'react-router-dom';
import { Box, Typography, Button, Grid } from '@mui/material';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
// title A is the top big centre title
// title B is the bottom right title
// bottom left title is always home
const HeroImgHeader = ({ titleA, titleB}) => {
    return (
        <Box 
        sx={{
            backgroundImage: `url('/images/heroimg_blur.jpg')`,
            backgroundRepeat: 'no-repeat',
            backgroundSize: 'cover',
            alignItems: 'center',
            display: 'flex',
            justifyContent: 'center',
            height: '30vh',
            borderRadius: '0',
        }}>
            <Grid container sx={{
                minWidth: '100px',
                width: { 
                    xs: '25%', // Full width on extra-small screens
                    md: '15%',  // Half width on medium screens
                    lg: '10%',  // Quarter width on large screens
                },
            }}>
                <Grid item xs={12} display="flex" justifyContent="center">
                    <Typography variant="h3" textAlign="center" color="white" fontFamily="Poppins" fontWeight="500" sx={{
                        fontSize: { 
                            xs: '8vw', 
                            md: '6vw', 
                            lg: '4vw', 
                        },
                    }}>
                        {titleA}
                    </Typography>
                </Grid>
                <Grid item xs={5} textAlign="center">
                    <Button variant="text" color="primary" component={Link} to="/home" sx={{
                        padding: '0',
                        margin: '0',
                        textTransform: 'none',
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        height: '100%',
                        "&:hover": {
                            backgroundColor: 'transparent',
                        }
                    }}>
                        <Typography variant="h6" color="white" fontFamily="Poppins" fontWeight="500" sx={{
                            fontSize: { 
                                xs: '3vw', 
                                md: '2vw', 
                                lg: '1vw', 
                            },
                            "&:hover": {
                                textDecoration: 'underline',
                            }
                        }}>
                            Home
                        </Typography>
                    </Button>
                </Grid>
                <Grid item xs={2} textAlign="center" sx={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                }}>
                    <Typography variant="h4" color="white" fontFamily="Poppins" fontWeight="500" fontSize='3vw' height="100%" sx={{
                        width: '100%', // Fill available width
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        fontSize: { 
                            xs: '3vw', 
                            md: '2vw', 
                            lg: '1vw', 
                        },
                    }}>
                        <ArrowForwardIosIcon sx={{marginY: 'auto', paddingBottom:'0px'}}/>
                    </Typography>
                </Grid>
                <Grid item xs={5} textAlign="center">
                    <Typography variant="h6" color="white" fontFamily="Poppins" fontWeight="300" fontSize='3vw'
                    sx={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: (titleB === 'Contact') ? 'flex-start' : (titleB === 'Confirmation') ? 'flex-start'  : (titleB === 'Checkout') ? 'flex-start'  :'center',
                        height: '100%',
                        fontSize: { 
                            xs: '3vw', 
                            md: '2vw', 
                            lg: '1vw', 
                        },
                    }}>
                        {titleB}
                    </Typography>
                </Grid>
            </Grid>
        </Box>
    );
};

export default HeroImgHeader;