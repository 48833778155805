import React, { useState, useEffect } from 'react';
import HomeProductCard from './HomeProductCard';
import axios from 'axios';
import Box from '@mui/material/Box';
import Skeleton from '@mui/material/Skeleton';
import Typography from '@mui/material/Typography';

function LatestRelease({ accessibilityState }) {
    const [isLoading, setIsLoading] = useState(true);
    const [latestReleaseList, setLatestReleaseList] = useState([]);
    const filePath =
  		process.env.NODE_ENV === 'production'
			? 'https://silverriot.co.uk/api/'
			: 'http://127.0.0.1/api/';

    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await axios.get(filePath + 'get_products.php',{
                    method: 'GET',
                    credentials: 'include',
                });
                setLatestReleaseList(response.data);
                setIsLoading(false);
            } catch (error) {
                console.error('There was an error!', error);
            }
        };
        fetchData();
    }, [filePath]);

	// useEffect(() => {
	// 	if (accessibilityState) {
	// 		playVideo();
	// 	} else {
	// 		pauseVideo();
	// 	}
	// }, [accessibilityState]);


    const repeatCount = 3;
    let repeatedArray = [];
    for (let i = 0; i < repeatCount; i++) {
      	repeatedArray = repeatedArray.concat(latestReleaseList);
    }

	return (
		<Box
		sx={{
			overflow: "hidden",
			display:'flex',
			flexDirection:'column',
		}}
		>
		<Box
			sx={{
			bgcolor: "#0B0A0A",
			paddingY: "8px",
			borderTop: "1px solid white",
			borderBottom: "1px solid white",
			}}
		>
			<Typography
			variant="h5"
			sx={{
				color: "white",
				textAlign: "center",
				fontFamily: "Inter, sans-serif",
				fontWeight: "200",
				letterSpacing: ".05em",
			}}
			>
			LATEST RELEASE
			</Typography>
		</Box>
		{isLoading ? (
			<>
			<Skeleton variant="rectangular" width={210} height={118} />
			<Skeleton variant="rectangular" width={210} height={118} />
			<Skeleton variant="rectangular" width={210} height={118} />
			</>
		) : (
			<Box
			sx={{
				backgroundColor: "#0B0A0A",
				display: "flex",
				justifyContent:'flex-end',
				flexDirection: "row",
				width: repeatedArray.length * 60 + "vw",
				maxWidth: repeatedArray.length * 45 + "vh",
				height: "60vw",
				maxHeight: "45vh",
				animation: "latestrelease 10s linear infinite",
				"@keyframes latestrelease": {
				"0%": {
					transform: `translateX(-${100/repeatCount}%)`,
				},
				"100%": {
					transform: `translateX(0%)`,
				},
				},
				':hover': {
				animationPlayState: 'paused',
				},
				animationPlayState: accessibilityState ? 'running' : 'paused',
			}}
			>
			{repeatedArray.map((product, index) => (
				<Box key={index} sx={{ aspectRatio: "1/1" }} padding={"10px"}>
					<HomeProductCard product={product} />
				</Box>
			))}
			</Box>
		)}
		</Box>
	);
}

export default LatestRelease;
