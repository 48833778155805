import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { useParams, Link, useNavigate } from 'react-router-dom';
import { Typography, Button, Grid, Box } from '@mui/material';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import HoverableImg from '../components/HoverableImg';

// renders then rerenders when fetchData is called

function Product({ cart, handleAddToCart, handleRemoveFromCart }) {
    const { id } = useParams();
    const [product, setProduct] = useState({name: 'Loading...'});
    const [mainUrl, setMainUrl] = useState(null);
    const [chosenSize , setChosenSize] = useState(null);
    const navigate = useNavigate();
    
    const filePath =
          process.env.NODE_ENV === 'production'
            ? 'https://silverriot.co.uk/api/'
            : 'http://127.0.0.1/api/';

    useEffect(() => {
        const fetchData = async () => {
        try {
            const response = await axios.post(filePath + 'get_products.php',{id});
            response.data.main_url = response.data.image_url_0;
            response.data.chosen_size = 'L';
            setChosenSize('L');
            setMainUrl(response.data.image_url_0);
            setProduct(response.data);
            console.log(response.data); 
        } catch (error) {
            console.error(error);
        }
        };

        fetchData();
    }, [id, filePath]);


    const handleSetChosenSize = (size) => {
        if (size === chosenSize) return;
        product.chosen_size = size;
        setChosenSize(size);
    };

    const handleSetMainUrl = (url) => {
        product.main_url = url
        setMainUrl(url);
    };

    return (
        <div>
            <Grid container sx={{
                backgroundColor:'#0B0A0A',
            }}>
                <Grid item textAlign="center" display="flex" alignItems='center'>
                    <Box component={Link} to="/shop" sx={{
                        padding: '0',
                        margin: '10px',
                        textTransform: 'none',
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        height: '3vh',
                        width: '80%',
                        marginRight:'0',
                        marginLeft:'20px',
                        color:"#9F9F9F",
                        fontFamily:"Poppins",
                        fontWeight:"500",   
                        fontSize: { 
                                xs: '2vw', 
                                md: '1.5vw', 
                                lg: '1vw', 
                            },
                        textDecoration: 'none',
                        "&:hover": {
                                textDecoration: 'underline',
                            },                  
                    }}>
                        Home
                    </Box>
                </Grid>
                <Grid item textAlign="center" display="flex" alignItems='center'>
                    <Typography variant="h4" color="white" fontFamily="Poppins" fontWeight="500" fontSize='3vw' height="100%" sx={{
                        width: '100%', // Fill available width
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        
                    }}>
                        <ArrowForwardIosIcon sx={{marginY: 'auto', paddingBottom:'0px'}}/>
                    </Typography>
                </Grid>
                <Grid item textAlign="center" display="flex" alignItems='center'>
                    <Box component={Link} to="/shop" sx={{
                        padding: '0',
                        margin: '10px',
                        textTransform: 'none',
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        height: '3vh',
                        width: '80%',
                        marginRight:'0',
                        marginLeft:'5px',
                        color:"#9F9F9F",
                        fontFamily:"Poppins",
                        fontWeight:"500",   
                        fontSize: { 
                                xs: '2vw', 
                                md: '1.5vw', 
                                lg: '1vw', 
                            },
                        textDecoration: 'none',
                        "&:hover": {
                                textDecoration: 'underline',
                            },                  
                    }}>
                        Shop
                    </Box>
                </Grid>
                <Grid item textAlign="center" display="flex" alignItems='center'>
                    <Typography variant="h4" color="white" fontFamily="Poppins" fontWeight="500" fontSize='3vw' height="100%" sx={{
                        width: '100%', // Fill available width
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        
                    }}>
                        <ArrowForwardIosIcon sx={{marginY: 'auto', paddingBottom:'0px'}}/>
                    </Typography>
                </Grid>
                <Grid item textAlign="center" display="flex" >
                    <Box sx={{
                    borderLeft: '1px solid white',
                    marginX: '20px',
                    marginY: '10px',
                    paddingX: '20px',
                }}>

                    <Typography variant="h6" color="white" fontFamily="Poppins" fontWeight="300" fontSize='3vw'
                    sx={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        textTransform: 'uppercase',
                        height: '100%',
                        fontSize: { 
                            xs: '2vw', 
                            md: '1.5vw', 
                            lg: '1vw', 
                        },
                    }}>
                        {product.secondary_name ? product.name + '[' + product.secondary_name + ']': product.name}
                    </Typography>
                    </Box>
                </Grid>
            </Grid>
            {product ? (    
            <Box sx={{
                display:'flex',
                flexDirection:'column',
            }}>
                <Box sx={{
                    display:'flex',
                    flexDirection:{lg:'row',xs:'column'}
                }}>
                    <Box sx={{
                        display:'flex',
                        flexDirection:{sm:'row',xs:'column'},
                        width:{lg:'50vw', xs:'100vw'},
                        height:{lg:'40vw', sm:'50vw', xs:'125vw'},
                    }}>
                        <Box sx={{
                            display:'flex',
                            flexDirection:{sm:'column', xs:'row'},
                            order:{lg:1, sm:1, xs:2},
                        }}>
                            <Box sx={{
                                width:{lg:'10vw', sm:'25vw', xs:'50vw'},
                                height:{lg:'20vw', sm:'50vw', xs:'25vw'},
                                display:'flex',
                                flexDirection:{sm:'column', xs:'row'},
                            }}>
                                <Box onClick={() => handleSetMainUrl(product.image_url_0)}
                                    sx={{
                                        overflow: "hidden", 
                                        cursor: "pointer", 
                                        border: mainUrl === product.image_url_0 ? '3px solid white' : '1px solid white',
                                        width: {lg:mainUrl === product.image_url_0 ? 'calc(10vw - 6px)' :'calc(10vw - 2px)', sm:mainUrl === product.image_url_0 ? 'calc(25vw - 6px)' :'calc(25vw - 2px)'},
                                        height: {lg:mainUrl === product.image_url_0 ? 'calc(10vw - 6px)' :'calc(10vw - 2px)', sm:mainUrl === product.image_url_0 ? 'calc(25vw - 6px)' :'calc(25vw - 2px)'},
                                        maxWidth:'25vw',
                                    }}>
                                    <img src={'/images/' + product.image_url_0} alt={product.name} style={{width:'100%',height:'100%'}} />
                                </Box>
                                <Box onClick={() => handleSetMainUrl(product.image_url_1)}
                                        sx={{
                                            overflow: "hidden", 
                                            cursor: "pointer", 
                                            border: mainUrl === product.image_url_1 ? '3px solid white' : '1px solid white',
                                            width: {lg:mainUrl === product.image_url_1 ? 'calc(10vw - 6px)' :'calc(10vw - 2px)', sm:mainUrl === product.image_url_1 ? 'calc(25vw - 6px)' :'calc(25vw - 2px)'},
                                            height: {lg:mainUrl === product.image_url_1 ? 'calc(10vw - 6px)' :'calc(10vw - 2px)', sm:mainUrl === product.image_url_1 ? 'calc(25vw - 6px)' :'calc(25vw - 2px)'},
                                        maxWidth:'25vw',
                                        }}>
                                        <img src={'/images/' + product.image_url_1} alt={product.name} style={{width:'100%',height:'100%'}} />
                                </Box>
                            </Box>
                            <Box sx={{
                                width:{lg:'10vw', sm:'0vw', xs:'50vw'},
                                height:{lg:'20vw', sm:'0vw', xs:'25vw'},
                                display:{lg:'block', sm:'none', xs:'flex'},
                                flexDirection:{sm:'column', xs:'row'},
                            }}>
                                <Box onClick={() => handleSetMainUrl(product.image_url_2)}
                                    sx={{
                                        overflow: "hidden", 
                                        cursor: "pointer", 
                                        border: mainUrl === product.image_url_2 ? '3px solid white' : '1px solid white',
                                        width: {lg:mainUrl === product.image_url_2 ? 'calc(10vw - 6px)' :'calc(10vw - 2px)', sm:mainUrl === product.image_url_2 ? 'calc(25vw - 6px)' :'calc(25vw - 2px)'},
                                        height: {lg:mainUrl === product.image_url_2 ? 'calc(10vw - 6px)' :'calc(10vw - 2px)', sm:mainUrl === product.image_url_2 ? 'calc(25vw - 6px)' :'calc(25vw - 2px)'},
                                        maxWidth:'25vw',
                                    }}>
                                    <img src={'/images/' + product.image_url_2} alt={product.name} style={{width:'100%',height:'100%'}} />
                                </Box>
                                <Box onClick={() => handleSetMainUrl(product.image_url_3)}
                                    sx={{
                                        overflow: "hidden", 
                                        cursor: "pointer", 
                                        border: mainUrl === product.image_url_3 ? '3px solid white' : '1px solid white',
                                        width: {lg:mainUrl === product.image_url_3 ? 'calc(10vw - 6px)' :'calc(10vw - 2px)', sm:mainUrl === product.image_url_3 ? 'calc(25vw - 6px)' :'calc(25vw - 2px)'},
                                        height: {lg:mainUrl === product.image_url_3 ? 'calc(10vw - 6px)' :'calc(10vw - 2px)', sm:mainUrl === product.image_url_3 ? 'calc(25vw - 6px)' :'calc(25vw - 2px)'},
                                        maxWidth:'25vw',
                                    }}>
                                    <img src={'/images/' + product.image_url_3} alt={product.name} style={{width:'100%',height:'100%'}} />
                                </Box>
                            </Box>
                        </Box>
                        <Box sx={{
                            width:{xl:'40vw', lg:'40vw', sm:'50vw'},
                            height:{xl:'40vw', lg:'40vw', sm:'50vw'},
                            order:{lg:2, sm:2, xs:1},
                        }}>
                            <HoverableImg src={'/images/' + product.main_url} alt={product.name} width={{xl:'40vw', lg:'50vw', sm:'100vw'}} height={{xl:'40vw', lg:'50vw', sm:'100vw'}}/>
                        </Box>

                        <Box sx={{
                                width:{lg:'0vw', sm:'25vw'},
                                height:{lg:'0vw', sm:'50vw'},
                                display:{lg:'none', sm:'block', xs:'none'},
                                order:{lg:3, sm:3, xs:3},
                        }}>
                            <Box onClick={() => handleSetMainUrl(product.image_url_2)}
                                sx={{
                                    overflow: "hidden", 
                                    cursor: "pointer", 
                                    border: mainUrl === product.image_url_2 ? '3px solid white' : '1px solid white',
                                    width: mainUrl === product.image_url_2 ? 'calc(25vw - 6px)' :'calc(25vw - 2px)',
                                    height: mainUrl === product.image_url_2 ? 'calc(25vw - 6px)' :'calc(25vw - 2px)',
                                    maxWidth:'25vw',
                                }}>
                                <img src={'/images/' + product.image_url_2} alt={product.name} style={{width:'100%',height:'100%'}} />
                            </Box>
                            <Box onClick={() => handleSetMainUrl(product.image_url_3)}
                                    sx={{
                                        overflow: "hidden", 
                                        cursor: "pointer", 
                                        border: mainUrl === product.image_url_3 ? '3px solid white' : '1px solid white',
                                        width: mainUrl === product.image_url_3 ? 'calc(25vw - 6px)' :'calc(25vw - 2px)',
                                        height: mainUrl === product.image_url_3 ? 'calc(25vw - 6px)' :'calc(25vw - 2px)',
                                        maxWidth:'25vw',
                                    }}>
                                    <img src={'/images/' + product.image_url_3} alt={product.name} style={{width:'100%',height:'100%'}} />
                            </Box>
                        </Box>
                    </Box>  

                    <Box sx={{
                        width:{lg:'50vw'},
                        height: { lg: 'calc(40vw - 2px)', xs:'auto' },
                        display: 'flex', 
                        flexDirection: 'column',
                        borderTop: {lg:'1px solid white', xs:'none'},
                        borderBottom: '1px solid white',
                    }}>
                        <Typography variant="h4" color='white' fontFamily="HelveticaNeue" textTransform="uppercase" sx={{paddingX:'1vw', paddingTop:'0.5vw'}}>{product.name}</Typography>
                        {product.secondary_name ? (
                            <Typography variant="h6" color='white' fontFamily="HelveticaNeue" textTransform="uppercase" sx={{paddingX:'1vw'}}>[{product.secondary_name}]</Typography>
                        ):(null)}
                        <Typography variant="body1" color="#9F9F9F" sx={{ paddingX: '1vw' }}>{product.out_of_stock == 1 ? `£${product.price} - This product is currently out of stock` : `£${product.price}`}</Typography>
                        <Typography variant="body1" marginTop="5%" paddingX='1vw' color="white">{product.description_0}</Typography>
                        <Typography variant="body1" marginTop="5%" paddingX='1vw' color="#9F9F9F">Size</Typography>
                        <Box>
                            <Button variant="contained" disabled={chosenSize === 'L'} sx={{
                                marginX:'1vw',
                                width:'5vw',
                                maxWidth:'50px',
                                minWidth:'50px', // to overwrite the default minWidth
                                height:'5vw',
                                maxHeight:'50px',
                                backgroundColor: '#0B0A0A',
                                color: 'white',
                                borderRadius: '15%',
                                
                                padding: '0',
                                textTransform: 'none',
                                fontFamily: 'Poppins',
                                fontWeight: '400',
                                fontSize: '2vw',
                                '&:hover': {
                                    backgroundColor: '#E51E10',
                                },
                                '&:disabled': {
                                    backgroundColor: product.out_of_stock == 1 ? '#9F9F9F' : '#E51E10',
                                    color: product.out_of_stock == 1 ? '#0B0A0A' : 'white',
                                },
                            }} onClick={() => handleSetChosenSize('L')}>L</Button>
                            {/* <Button variant="contained" disabled={chosenSize == 'M'} sx={{
                                width:'5vw',
                                minWidth:'5vw',
                                height:'5vw',
                                backgroundColor: '#0B0A0A',
                                color: 'white',
                                borderRadius: '1vw',
                                padding: '0',
                                textTransform: 'none',
                                fontFamily: 'Poppins',
                                fontWeight: '400',
                                fontSize: '2vw',
                                '&:hover': {
                                    backgroundColor: '#E51E10',
                                },
                                '&:disabled': {
                                    backgroundColor: '#E51E10',
                                    color: 'white',
                                },
                            }} onClick={() => handleSetChosenSize('M')}>M</Button> */}
                        </Box>
                        <div style={{ flexGrow: 1 }}></div>
                        <Box sx={{ paddingX:'1vw', paddingBottom:'1vw', display:'flex', flexDirection:'row', justifyContent:cart.includes(id) ? "space-between" : 'start'}}>
                            <Button variant="outlined"  
                                disabled={product.out_of_stock == 1} //currently just hides the component
                                sx={{
                                    color: 'white',
                                    borderColor: 'white',
                                    borderRadius: '10px',
                                    padding: '10px',
                                    paddingX: '20px',
                                    textTransform: 'none',
                                    fontFamily: 'Poppins',
                                    fontWeight: '400',
                                    fontSize: '2vw',
                                    '&:hover': {
                                        borderColor: 'white',
                                        
                                    },
                                    '&:disabled': {
                                        backgroundColor: '#9F9F9F',
                                        color: '#0B0A0A',
                                    },
                                    marginTop:'10px',
                                }} 
                                onClick={() => {
                                    handleAddToCart(id)
                            }}>
                                {product.out_of_stock == 0 ? "Add To Cart" : "Out of Stock"}
                            </Button>
                            {cart.length === 0 && product.out_of_stock == 0 ? (
                                <Button variant="outlined" sx={{
                                    color: '#0B0A0A',
                                    backgroundColor: 'white',
                                    borderColor: '#0B0A0A',
                                    borderRadius: '10px',
                                    padding: '10px',
                                    paddingX: '20px',
                                    textTransform: 'none',
                                    fontFamily: 'Poppins',
                                    fontWeight: '400',
                                    fontSize: '2vw',
                                    '&:hover': {
                                        borderColor: '#0B0A0A',
                                        backgroundColor: '#9F9F9F',
                                    },
                                    marginTop:'10px',
                                    }} 
                                    onClick={() => {
                                        handleAddToCart(id);
                                        product.quantity = 1;
                                        navigate('/checkout',{ state: { cartList: [product], totalPrice: product.price  }, replace: true});
                                }}>
                                    Buy Now
                                </Button>
                            ) : cart.includes(id) ? (
                                <Button variant="outlined" sx={{
                                    color: 'white',
                                    backgroundColor: '#721E1D',
                                    borderColor: 'white',
                                    borderRadius: '10px',
                                    padding: '10px',
                                    paddingX: '20px',
                                    textTransform: 'none',
                                    fontFamily: 'Poppins',
                                    fontWeight: '400',
                                    fontSize: '2vw',
                                    '&:hover': {
                                        borderColor: 'white',
                                        backgroundColor: '#5f1c1a',
                                    },
                                    marginTop:'10px',
                                    }} 
                                    onClick={() => {
                                        handleRemoveFromCart(id)
                                }}>
                                    Remove from cart
                                </Button>
                            ) : (
                                null
                            )}
                        </Box>
                    </Box>
                </Box>    
                
                <Grid item xs={12}>
                    <Box sx={{
                        width:'100%',
                        display:'flex',
                        alignItems:'center',
                        justifyContent:'center',
                        flexDirection:'column',
                    }}>
                        <Typography variant="h6" color="white" marginTop="5%" sx={{
                            textAlign: 'center',
                            paddingY: '2px',
                            paddingX: '20px',
                            borderBottom: '2px solid white',
                            width:'auto',
                            fontFamily:'Poppins',
                            fontWeight:'400',

                        }}>Additional Information</Typography>
                        <Box sx={{
                            width:'80%',
                        }}>
                            <Typography variant="body1" color="white" sx={{ fontFamily:'Poppins', fontWeight:'400', marginTop:'20px'}}><span style={{fontWeight:'700', color:'white', fontSize:'30px' }}>Material:</span> {product.material}</Typography>
                            <Typography variant="body1" color="white" sx={{ fontFamily:'Poppins', fontWeight:'400', marginTop:'20px'}}><span style={{fontWeight:'700', color:'white', fontSize:'30px' }}>Care:</span> {product.care}</Typography>
                            <Typography variant="body1" color="white" sx={{ fontFamily:'Poppins', fontWeight:'400', marginTop:'20px'}}><span style={{fontWeight:'700', color:'white', fontSize:'30px' }}>Manufacturing:</span> {product.manufacturing}</Typography>

                            <Typography variant="body1" color="white" sx={{ fontFamily:'Poppins', fontWeight:'400', marginTop:'20px'}}><span style={{fontWeight:'700', color:'white', fontSize:'30px' }}>Properties:</span></Typography>
                            <Typography variant="body1" color="white" sx={{ fontFamily:'Poppins', fontWeight:'400'}}>Weight: {product.weight} grams {product.type === 'ring' ? '(size dependent)' : ''}</Typography>
                            <Typography variant="body1" color="white" sx={{ fontFamily:'Poppins', fontWeight:'400'}}>Size: {chosenSize === 'L' ? 'Large (L) - ' : 'Loading ...'}{product.length} {product.type === 'bracelet' ? 'cm' : ''}</Typography>
                            <Typography variant="body1" color="white" sx={{ fontFamily:'Poppins', fontWeight:'400'}}>{product.clasp !== '' ? `Clasp Type: ${product.clasp}` : ''}</Typography>
                            <Typography variant="body1" color="white" sx={{ fontFamily:'Poppins', fontWeight:'400', marginTop:'20px'}}>{product.description_1}</Typography>
                        </Box>
                        <Box sx={{
                            width:'100%',
                            display:'flex',
                            flexDirection:'row',
                            height:'25vw',
                            justifyContent:'space-evenly',
                            marginY:'30px'
                        }}>
                            <HoverableImg src={'/images/' + product.image_url_4} alt={product.name} ratio={1} width={'25%'}/>
                            <HoverableImg src={'/images/' + product.image_url_5} alt={product.name} ratio={1} width={'25%'}/>
                            <HoverableImg src={'/images/' + product.image_url_6} alt={product.name} ratio={1} width={'25%'}/>
                        </Box>
                    </Box>
                </Grid>
            </Box>
        ) : (
            <Typography variant="body1">Loading...</Typography>
        )}
        </div>
    );
}

export default Product;
