import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { Card, CardActionArea, CardContent, Typography, CardMedia } from '@mui/material';

const HomeProductCard = ({ product }) => {
    const { name, secondary_name, price, image_url_0, id } = product;
    const out_of_stock = product.out_of_stock === '1';
    const [isHovered, setIsHovered] = useState(false);

    return (
      <Card
        sx={{ position: "relative", width: "100%", height: "100%" }}
        onMouseEnter={() => setIsHovered(true)}
        onMouseLeave={() => setIsHovered(false)}
      >
        <CardActionArea
          component={Link}
          to={`/product/${id}`}
          sx={{ height: "100%" }}
        >
          <CardMedia
            component="img"
            height="100%"
            src={"/images" + image_url_0}
            alt={name}
            sx={{
              objectFit: "cover",
              transition: "transform 0.3s",
              transform: isHovered ? "scale(1.1)" : "scale(1)",
            }}
          />
          {!!out_of_stock && (
            <div
              style={{
                position: "absolute",
                top: 0,
                left: 0,
                width: "100%",
                height: "20%",
                marginTop: "40%",
                backgroundColor: "rgba(255, 255, 255, 0.9)",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <Typography
                variant="h6"
                sx={{
                  color: "#333",
                  textAlign: "center",
                  fontFamily: "Raleway, sans-serif",
                  fontWeight: "bold",
                  fontSize: "100%",
                }}
              >
                OUT OF STOCK
              </Typography>
            </div>
          )}
          {isHovered && (
            <CardContent
              sx={{
                position: "absolute",
                bottom: 0,
                left: 0,
                width: "100%",
                backgroundColor: "rgba(0, 0, 0, 0.3)",
                color: "white",
                textAlign: "center",
                paddingY: "8px",
                paddingX: "0",
              }}
            >
              <Typography
                variant="h4"
                sx={{
                  color: "white",
                  textAlign: "center",
                  fontFamily: "HelveticaNeue, sans-serif",
                  fontWeight: "200",
                  letterSpacing: ".04em",
                  textShadow: "1px 1px 1px rgba(0,0,0,.5)",
                }}
              >
                {name} {secondary_name ? `[${secondary_name}]` : ""}
              </Typography>
              <Typography
                variant="h5"
                sx={{
                  color: "white",
                  textAlign: "center",
                  fontFamily: "HelveticaNeue, sans-serif",
                  fontWeight: "bold",
                  letterSpacing: ".05em",
                  textShadow: "1px 1px 1px rgba(0,0,0,.5)",
                }}
              >
                £{price}
              </Typography>
            </CardContent>
          )}
        </CardActionArea>
      </Card>
    );
};

export default HomeProductCard;
