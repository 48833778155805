import React, { useState, useEffect } from 'react';
import { AppBar, Toolbar, IconButton, Badge, Drawer, Grid, Box } from '@mui/material';
import { Menu as MenuIcon, ShoppingCart as ShoppingCartIcon } from '@mui/icons-material';
import { NavLink } from 'react-router-dom';
import Marquee from './Marquee';
import { useTheme } from '@material-ui/core/styles';
import { useMediaQuery } from '@material-ui/core';

const NavBarMob = ({ cart }) => {
    const [cartLength, setCartLength] = useState(null);
    const [drawerOpen, setDrawerOpen] = useState(false);
    const toggleDrawer = (open) => (event) => {
        if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
            return;
        }
        setDrawerOpen(open);
    };
    useEffect(() => {
        setCartLength(Object.keys(cart).length);
    }, [cart]);

    const theme = useTheme();
    const isXs = useMediaQuery(theme.breakpoints.only('xs'));
    const isSm = useMediaQuery(theme.breakpoints.only('sm'));
    const iconFontSize = isXs ? '1.75rem' : isSm ? '3rem' : '4rem'; // Adjust as needed
    return (
        <AppBar position="static" style={{ backgroundColor: '#0B0A0A'}}>
            <Marquee text="FREE SHIPPING ON ALL ORDERS ACROSS THE UK" />
            <Toolbar>
                <Grid container>
                    <Grid item xs={5} container justifyContent="flex-start">
                        <IconButton color="inherit" aria-label="menu" onClick={toggleDrawer(true)}>
                            <MenuIcon style={{fontSize:iconFontSize}}/>
                        </IconButton>
                        <Drawer open={drawerOpen} onClose={toggleDrawer(false)}>
                            <Box sx={{ backgroundColor:"#0B0A0A", height:'100%', display:'flex', flexDirection:'column'}}>

                                <Box component={NavLink} to="/home"
                                sx={{ 
                                    color: 'white', 
                                    textDecoration: 'none', 
                                    textTransform: 'uppercase', 
                                    fontSize:{xs:'10vw', sm:'4vw', md:'2vw'}, 
                                    fontFamily:'HelveticaNeue, sans-serif',
                                    '&:hover': { // Apply styles to visited links
                                        textDecoration: 'underline', // or any other color you want visited links to be
                                    },
                                    '&:active': {
                                        textDecoration: 'underline'
                                    },
                                    
                                    padding:'5px'
                                }}>
                                    Home
                                </Box>
                                <Box component={NavLink} to="/shop"
                                sx={{ 
                                    color: 'white', 
                                    textDecoration: 'none', 
                                    textTransform: 'uppercase', 
                                    fontSize:{xs:'10vw', sm:'4vw', md:'2vw'}, 
                                    fontFamily:'HelveticaNeue, sans-serif',
                                    '&:visited': { // Apply styles to visited links
                                        color: 'white', // or any other color you want visited links to be
                                    },
                                    padding:'5px'
                                }}>
                                    Shop
                                </Box>
                                <Box component={NavLink} to="/about"
                                sx={{ 
                                    color: 'white', 
                                    textDecoration: 'none', 
                                    textTransform: 'uppercase', 
                                    fontSize:{xs:'10vw', sm:'4vw', md:'2vw'}, 
                                    fontFamily:'HelveticaNeue, sans-serif',
                                    '&:visited': { // Apply styles to visited links
                                        color: 'white', // or any other color you want visited links to be
                                    },
                                    padding:'5px'
                                }}>
                                    About
                                </Box>
                                <Box component={NavLink} to="/contact"
                                sx={{ 
                                    color: 'white', 
                                    textDecoration: 'none', 
                                    textTransform: 'uppercase', 
                                    fontSize:{xs:'10vw', sm:'4vw', md:'2vw'}, 
                                    fontFamily:'HelveticaNeue, sans-serif',
                                    '&:visited': { // Apply styles to visited links
                                        color: 'white', // or any other color you want visited links to be
                                    },
                                    padding:'5px',
                                    marginRight:'3px'
                                }}>
                                    Contact
                                </Box>
                                <Box component={NavLink} to="/info"
                                sx={{ 
                                    color: 'white', 
                                    textDecoration: 'none', 
                                    textTransform: 'uppercase', 
                                    fontSize:{xs:'10vw', sm:'4vw', md:'2vw'}, 
                                    fontFamily:'HelveticaNeue, sans-serif',
                                    '&:visited': { // Apply styles to visited links
                                        color: 'white', // or any other color you want visited links to be
                                    },
                                    padding:'5px',
                                    marginRight:'3px'
                                }}>
                                    Info
                                </Box>
                            </Box>
                        </Drawer>
                    </Grid>
                    <Grid item xs={2} container sx={{
                        justifyContent:"center",
                        height: {xs:'30px', sm:'50px'},
                        marginTop: {xs:'12px', sm:'20px'}
                    }}>
                        <NavLink to="/" style={{height:'100%'}}>
                            <img src="/images/logotext.svg" alt="logo" style={{ height:"100%"}} />
                        </NavLink>
                    </Grid>
                    <Grid item xs={5} container justifyContent="flex-end">
                        <IconButton color="inherit" aria-label="shopping cart" component={NavLink} to="/cart">
                            <Badge badgeContent={cartLength || 0} sx={{
                                "& .MuiBadge-badge": {
                                color: "white",
                                backgroundColor: "#721e1d",
                                margin:"0.3vw"
                                }
                            }}>
                                <ShoppingCartIcon style={{fontSize:iconFontSize}}/>
                            </Badge>
                        </IconButton>
                    </Grid>
                </Grid>
               
                
            </Toolbar>
        </AppBar>
    );
};

export default NavBarMob;

