// const CustomTextField = ({ name, value, handleChange, required, placeholder, sx }) => {


//     return (
//         <input 
//             type="text" 
//             name={name} 
//             value={value} 
//             onChange={handleChange} 
//             required={required}
//             placeholder={placeholder}
//             style={{
//                 width: 'calc(100% - 42px)',
//                 margin: '10px',
//                 backgroundColor: 'transparent',
//                 border: '1px solid #9f9f9f',
//                 height: '60px',
//                 borderRadius: '5px',
//                 color: 'white',
//                 fontFamily:'Poppins',
//                 padding:'0 10px',
//                 fontSize:'20px',
//                 ...sx,
//             }}
//         />
//     );
// };

// export default CustomTextField;



import styled from 'styled-components';

const objectToCss = (obj) => {
    return Object.entries(obj).map(([key, value]) => {
        // Convert camelCase to kebab-case
        const kebabKey = key.replace(/([a-z0-9])([A-Z])/g, '$1-$2').toLowerCase();
        return `${kebabKey}: ${value};`;
    }).join(' ');
};

// Define a StyledInput component using styled-components
const StyledInput = styled.input`
    width: calc(100% - 42px);
    margin: 10px;
    background-color: transparent;
    border: 1px solid #9f9f9f;
    height: 60px;
    border-radius: 5px;
    color: white;
    font-family: 'Poppins';
    padding: 0 10px;
    font-size: 20px;
    ${props => props.sx && { ...props.sx }}
    ${props => props.pseudoClass && Object.entries(props.pseudoClass).map(([pseudo, styles]) => `${pseudo} { ${objectToCss(styles)} }`).join(' ')}

`;

const CustomTextField = ({ name, value, handleChange, required, placeholder, sx, pseudoClass }) => {
    return (
        <StyledInput 
            type="text" 
            name={name} 
            value={value} 
            onChange={handleChange} 
            required={required}
            placeholder={placeholder}
            sx={sx} // Pass inline styles
            pseudoClass={pseudoClass} // Pass pseudo-class styles
        />
    );
};


export default CustomTextField;