import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import HeroImgHeader from '../components/HeroImgHeader';
import { Typography, Grid, Button, Checkbox } from '@mui/material';
import CustomTextField from '../components/CustomTextField';
import axios from 'axios';
import {loadStripe} from '@stripe/stripe-js';
import { EmbeddedCheckoutProvider, EmbeddedCheckout
} from '@stripe/react-stripe-js';
import '@geoapify/geocoder-autocomplete/styles/minimal.css';
import { v4 as uuidv4 } from 'uuid'; // Importing uuidv4 function from uuid library

// import TestColours from '../components/TestColours';

function Checkout({ handleSetOrderCode }) {

    const stripePromise = 
        process.env.NODE_ENV === 'production'
            ? loadStripe(process.env.REACT_APP_STRIPE_KEY_LIVE)
            : loadStripe(process.env.REACT_APP_STRIPE_KEY_TEST);
    const location = useLocation();
    const cartList = location.state.cartList;
    const totalPrice = location.state.totalPrice;
    const [showStripe, setShowStripe] = useState(false);
    const [uuid, setUuid] = useState('');
    const [tandcChecked, setTandcChecked] = useState(false);
    const [details, setDetails] = useState({
        firstName: '',
        lastName: '',
        companyName: '',
        country: '',
        // city: '',
        streetAddress: '',
        postcode: '',
        phone: '',
        email: ''
    });
    const filePath =
  		process.env.NODE_ENV === 'production'
			? 'https://silverriot.co.uk/api/'
			: 'http://127.0.0.1/api/';

    const [clientSecret, setClientSecret] = useState('');
    useEffect(() => {
		const startSession = async () => {
			try {
				const response = await axios.post(filePath + "create_stripe_session.php",{ cartList:cartList, uuid:uuid,} , {
					method: 'POST',
                    credentials: 'include',
                });
				setClientSecret(response.data.clientSecret);
                
			} catch (error) {
				console.error(error);
			}
		};
        if (uuid === '') {
            return;
        }
        startSession();
    }, [cartList, filePath, uuid]);

    const handleChange = (e) => {
        const { name, value } = e.target;
        setDetails(prevState => ({
            ...prevState,
            [name]: value
        }));
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        const postOrderDetails = async () => {
            try {
				const response = await axios.post(filePath + "create_order.php", JSON.stringify({ details:details, cartList:cartList, uuid:uuid }), {
					method: 'POST',
                    credentials: 'include',
                });
                handleSetOrderCode(response.data.order_code); 
			} catch (error) {
				console.error(error);
			}}
        postOrderDetails();
        setShowStripe(true);
    };

    useEffect(() => {
        // Generate UUID when the component mounts
        const generatedUuid = uuidv4();
        setUuid(generatedUuid);
    }, []);

    return (
        <div>
            <HeroImgHeader titleA="Checkout" titleB="Checkout"/>
            <form onSubmit={handleSubmit}>
                <Grid container>
                    <Grid item xs={12} sm={6} sx={{padding:'2%', paddingLeft:{md:'1%', lg:'10%', xl:'20%'}}}>
                        <Typography variant="h4" sx={{ fontFamily:'Poppins', fontWeight:'500', color:'white', width:'100%', textAlign:'left', padding:'2%' }}>
                            Billing details
                        </Typography>
                        <Grid container>
                            <Grid item xs={12} sm={6} >
                                <Typography sx={{ fontFamily:'Poppins', fontWeight:'500', color:'white', marginLeft:'10px'}}>First Name</Typography>
                                <CustomTextField name="firstName" value={details.firstName} handleChange={handleChange} required />
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <Typography sx={{ fontFamily:'Poppins', fontWeight:'500', color:'white', marginLeft:'10px'}}>Last Name</Typography>
                                <CustomTextField name="lastName" value={details.lastName} handleChange={handleChange} required />
                            </Grid>
                            <Grid item xs={12}>
                                <Typography sx={{ fontFamily:'Poppins', fontWeight:'500', color:'white', marginLeft:'10px'}}>Company Name (Optional)</Typography>
                                <CustomTextField name="companyName" value={details.companyName} handleChange={handleChange} />
                            </Grid>
                            <Grid item xs={12}>
                                <Typography sx={{ fontFamily:'Poppins', fontWeight:'500', color:'white', marginLeft:'10px'}}>Country / Region</Typography>
                                <CustomTextField name="country" value={details.country} handleChange={handleChange} required />
                            </Grid>
                            <Grid item xs={12}>
                                <Typography sx={{ fontFamily:'Poppins', fontWeight:'500', color:'white', marginLeft:'10px'}}>Street Address</Typography>
                                <CustomTextField name="streetAddress" value={details.streetAddress} handleChange={handleChange} required />
                            </Grid>
                            <Grid item xs={12}>
                                <Typography sx={{ fontFamily:'Poppins', fontWeight:'500', color:'white', marginLeft:'10px'}}>Postcode</Typography>
                                <CustomTextField name="postcode" value={details.postcode} handleChange={handleChange} required />
                            </Grid>
                            <Grid item xs={12}>
                                <Typography sx={{ fontFamily:'Poppins', fontWeight:'500', color:'white', marginLeft:'10px'}}>Phone number</Typography>
                                <CustomTextField name="phone" value={details.phone} handleChange={handleChange} required />
                            </Grid>
                            <Grid item xs={12}>
                                <Typography sx={{ fontFamily:'Poppins', fontWeight:'500', color:'white', marginLeft:'10px'}}>Email</Typography>
                                <CustomTextField name="email" value={details.email} handleChange={handleChange} required />
                            </Grid>
                        </Grid>
                    </Grid>
                    {(showStripe === false) ? (
                        <Grid container item xs={12} sm={6}  sx={{ padding:"2%", paddingRight:{md:'1%', lg:'10%', xl:'20%'} }}>
                            <Grid item xs={6} sx={{ borderBottom:'1px solid white' }} >
                                <Typography color="white" marginTop="20px">
                                    Product
                                </Typography>
                            </Grid>
                            <Grid item xs={6} sx={{ borderBottom:'1px solid white' }} >
                                <Typography color="white" textAlign="right" marginTop="20px">
                                    Subtotal
                                </Typography>
                            </Grid>
                            {cartList.map((product, index) => (
                                <React.Fragment key={index}>
                                <Grid item xs={6}>
                                    <Typography color='#9F9F9F'>
                                        {product.name} X {product.quantity}
                                    </Typography>
                                </Grid>
                                <Grid item xs={6}>
                                    <Typography color='#9F9F9F' textAlign="right">
                                        £ {product.price * product.quantity}
                                    </Typography>
                                </Grid>
                                </React.Fragment>
                            ))}
                            <Grid item xs={6}>
                                <Typography color='#9F9F9F'>
                                    Shipping
                                </Typography>
                            </Grid>
                            <Grid item xs={6}>
                                <Typography color='#9F9F9F' textAlign="right">
                                    Free
                                </Typography>
                            </Grid>
                            <Grid item xs={6}>
                                <Typography variant="h4" color="white" sx={{ paddingTop:'20px', paddingBottom:'10px', borderBottom:'1px solid white'}}>
                                    Total
                                </Typography>
                            </Grid>
                            <Grid item xs={6}>
                                <Typography variant="h4" color="white" textAlign="right" sx={{ paddingTop:'20px', paddingBottom:'10px', borderBottom:'1px solid white'}}>
                                    £ {totalPrice}
                                </Typography>
                            </Grid>
                            <Grid item xs={10}>
                                <Typography color="white" sx={{ paddingTop:'20px', paddingBottom:'10px'}}>
                                    Please check the box to confirm you have read and agree to our terms and conditions.
                                </Typography>
                            </Grid>
                            <Grid item xs={2} sx={{ display:"flex", justifyContent:"center", alignItems:"center"}}>
                                <Checkbox
                                    checked={tandcChecked}
                                    onChange={() => setTandcChecked(!tandcChecked)}
                                    name="checked"
                                    sx={{
                                        color: 'white',
                                        '&$checked': {
                                            color: 'white',
                                        },
                                    }}
                                />
                            </Grid>
                            <Grid item xs={12} sx={{ display:"flex", justifyContent:"center"}}>
                                    <Button type="submit" variant="outline" disabled={tandcChecked === false}
                                     sx={{
                                        width: '60%',
                                        padding: '2%',
                                        marginY: '70px',
                                        color: 'white',
                                        border: '2px solid white',
                                        borderRadius: '12px',
                                        textTransform: 'none',
                                        fontSize: '1.5rem',
                                        fontFamily:"Poppins",
                                        fontWeight: '400',
                                        '&:hover': {
                                            border: '2px solid white',
                                        },
                                        '&:disabled': {
                                            border: '2px solid #9F9F9F',
                                            color: '#9F9F9F',
                                        }
                                    }}>
                                        Place order
                                    </Button>
                            </Grid>
                            <Grid item xs={12} height="100%"></Grid>
                        </Grid>
                    ) : (
                        <Grid item xs={12} sm={6} sx={{
                            padding:'2%', paddingRight:{md:'1%', lg:'10%', xl:'20%'}
                        }}>
                            <div id="checkout">
                                {clientSecret && (
                                    <EmbeddedCheckoutProvider
                                    stripe={stripePromise}
                                    options={{clientSecret}}
                                    >
                                    <EmbeddedCheckout />
                                    </EmbeddedCheckoutProvider>
                                )}
                            </div>
                        </Grid>
                    )}
                </Grid>
            </form>
        </div>
    );
}

export default Checkout;