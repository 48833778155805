import React, { useState } from 'react'
import { GeoapifyGeocoderAutocomplete, GeoapifyContext } from '@geoapify/react-geocoder-autocomplete'
import '@geoapify/geocoder-autocomplete/styles/minimal.css'
 
const Test = () => {
  
    if (process.env.NODE_ENV === 'development') {
    console.log('You are in development mode.');
    } else if (process.env.NODE_ENV === 'production') {
    console.log('You are in production mode.');
    } else if (process.env.NODE_ENV === 'test') {
    console.log('You are in test mode.');
    } else {
    console.log('The environment is unknown.');
    }
  function onPlaceSelect(value) {
    console.log(value);
  }
 
  function onSuggectionChange(value) {
    console.log(value);
  }
 const displayValue = null
  return <GeoapifyContext apiKey="47230b6d48bd42bd97cedc9d636338dd">
      <GeoapifyGeocoderAutocomplete placeholder="Enter address here"
        value={displayValue}
        placeSelect={onPlaceSelect}
        suggestionsChange={onSuggectionChange}
        // type={'street'}
        />
    </GeoapifyContext>
}
 
export default Test