import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Card, Typography, Button, Grid, Table, TableBody, TableCell, TableRow, TableContainer, TableHead, Box } from '@mui/material';
import '@geoapify/geocoder-autocomplete/styles/minimal.css';
// WIP: import { GeoapifyContext, GeoapifyGeocoderAutocomplete } from '@geoapify/react-geocoder-autocomplete';
// import Test from '../components/test';
import HeroImgHeader from '../components/HeroImgHeader';
import AspectRatio from '@mui/joy/AspectRatio';
// import TestColours from '../components/TestColours';
import DeleteButtonPopup from '../components/DeleteButtonPopup';
import { useNavigate } from 'react-router-dom';
import { TiDeleteOutline } from 'react-icons/ti';
import { IconButton } from '@mui/material';

const Cart = ({ cart, setCart, handleAddToCart, handleRemoveFromCart, contactDetails, setContactDetails }) => {
	const navigate = useNavigate();
    const [cartList, setCartList] = useState([]);
	const [totalPrice, setTotalPrice] = useState(0);
    const [isLoading, setIsLoading] = useState(true);
	const filePath =
  		process.env.NODE_ENV === 'production'
			? 'https://silverriot.co.uk/api/'
			: 'http://127.0.0.1/api/';
	
    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await axios.post(filePath + 'get_cart.php',{ 'cart' : cart }, {
					method: 'POST',
                    credentials: 'include',
                });
                setCartList(response.data);
				let totalPrice = response.data.reduce((total, item) => total + item.price * item.quantity, 0);
				setTotalPrice(totalPrice);
                setIsLoading(false);
            } catch (error) {
                console.error('There was an error!', error);
            }
        };
        fetchData();
    }, [cart, filePath]);


    return (
        <Card>
			<HeroImgHeader titleA={'Cart'} titleB={'Cart'} />
            {(isLoading !== true) ? (
				<Grid container padding="5%" sx={{
					backgroundColor: '#0B0A0A',
				}}>
					<Grid item xs={12} sm={8} md={9} >
						<TableContainer sx={{ width:{xs:"100%", sm:'90%',}, margin:"auto", overflow:'visible' }}>
							<Table aria-label="simple table">
								<TableHead sx={{
									border : '1px solid #721E1D',
									borderRadius: '3px',
									backgroundColor: '#0B0A0A',	
								}}>
									<TableRow>
										<TableCell width={{xs:"0", sm:'20%',}} sx={{ border: 'none',}}></TableCell>
										<TableCell align="left" width="20%" sx={{ border: 'none', color: 'white', fontFamily:'Poppins', fontWeight:'500' }}>Product</TableCell>
										<TableCell align="left" width="20%" sx={{ border: 'none', color: 'white', fontFamily:'Poppins', fontWeight:'500' }}>Price</TableCell>
										<TableCell align="left" width="20%" sx={{ border: 'none', color: 'white', fontFamily:'Poppins', fontWeight:'500' }}>Quantity</TableCell>
										<TableCell align="left"  sx={{ width:{xs:"0", sm:'20%',}, display:{xs:"none", sm:'block',}, border: 'none', color: 'white', fontFamily:'Poppins', fontWeight:'500' }}>Subtotal</TableCell>
									</TableRow>
								</TableHead>
								<TableBody sx={{
									backgroundColor: 'transparent',
									border : 'none'	
								
								}}>
								{cartList.map((row) => (
									<TableRow
									key={row.name}
									sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
									>
									<TableCell component="th" scope="row">
										<AspectRatio ratio={4/4} sx={{
											borderRadius:"10px",
											overflow:"hidden", 
										}}>
											<img src={'images' + row.image_url_0} alt={row.name} style={{
												width: '100%',
												height: 'auto',
											}}/>
										</AspectRatio>
										
									</TableCell>
									<TableCell align="left" sx={{ color: '#9F9F9F', fontFamily:'Poppins', fontWeight:'400' }}>{row.name}</TableCell>
									<TableCell align="left" sx={{ color: '#9F9F9F', fontFamily:'Poppins', fontWeight:'500' }}>£ {row.price}</TableCell>
									<TableCell align="left" sx={{ color: '#9F9F9F', fontFamily:'Poppins', fontWeight:'500' }}>
										<Box sx={{ position:'relative', display:{xs:'flex', sm:'block'} }}>
											<DeleteButtonPopup product={row} handleRemoveFromCart={handleRemoveFromCart}/>
											<Button component={Typography}
												sx={{ 
													color: 'white',
													padding: '0',
													margin:'0',
													width:'33%',
													minWidth:'33%',
													cursor: 'default', 
													borderRadius: {xs:"2px", sm:'0',},
													"&:hover": {
														backgroundColor: 'transparent',
														cursor: 'default', 
													},
													border: '1px solid #721E1D',
												}}
											>
												{row.quantity}
											</Button>
												
											<Button
											onClick={() => {handleAddToCart(row.id)}}
											sx={{ 
													color: 'white',
													padding: '0', 
													margin:'0', 
													display:{xs:"none", sm:'inline-flex',},
													width:{xs:"0", sm:'33%',}, 
													minWidth:{xs:"0", sm:'33%',},
													borderRadius: '0',
													borderTopRightRadius: '5px',
													borderBottomRightRadius: '5px',
													border: '1px solid #721E1D',
													borderLeft: 'none',
												}}
											>
												+
											</Button>
											<IconButton color="inherit" aria-label="shopping cart" onClick={() => {handleRemoveFromCart(row.id)}}
											sx={{
												display:{xs:"flex", sm:'none',},
												padding: '0',
												margin:'0',
												color: 'white',
												paddingLeft:'6px'
											
											}}>
												<TiDeleteOutline style={{ color:'white', marginRight:'20px'}} />
											</IconButton>
										</Box>
									</TableCell>
									<TableCell align="left" sx={{ width:{xs:"0", sm:'20%',}, display:{xs:"none", sm:'table-cell',}, color: 'white', fontFamily:'Poppins', fontWeight:'500' }}>£ {(row.price * row.quantity).toFixed(2)}</TableCell>
									</TableRow>
								))}
								</TableBody>
							</Table>
						</TableContainer>
					</Grid>
					<Grid item xs={12} sm={4} md={3}>
						<Box sx={{
							border : '1px solid #721E1D',
							borderRadius: '3px',
							backgroundColor: '#0B0A0A',	
							paddingX: {xs:'5%', md:'10%', lg:'12%'},
							paddingY: {xs:'5%', md:'8%', lg:'5%'},
						}}>
								<Typography variant="h4" width="100%" textAlign="center" color="white" fontWeight="600" fontFamily="Poppins" sx={{
									fontSize: {xs:'1.5rem', md:'1.75rem', lg:'2.125rem'},
								}}>
									Cart Totals
								</Typography>
								<Grid container sx={{
									paddingX: {xs:'5%', sm:'10%', md:'10%', lg:'12%'},
								}}>
									<Grid item xs={6}>
										<Typography variant="h6" sx={{
											textAlign:{xs:"left", lg:"left"},
											color:"white",
											fontFamily:"Poppins",
											width:"100%",
										}}  >
											Subtotal
										</Typography>
									</Grid>
									<Grid item xs={6}>
										<Typography variant="h6" sx={{
											textAlign:{xs:"right", lg:"right"},
											color:"#9F9F9F",
											fontFamily:"Poppins",
											width:"100%",
										}}>
											£{totalPrice}
										</Typography>
									</Grid>
									<Grid item xs={6}>
										<Typography variant="h6" sx={{
											textAlign:{xs:"left", lg:"left"},
											color:"white",
											fontFamily:"Poppins",
											width:"100%",
										}}>
											Total
										</Typography>
									</Grid>
									<Grid item xs={6}>
										<Typography variant="h6" sx={{
											textAlign:{xs:"right", lg:"right"},
											color:"#E51E10",
											fontFamily:"Poppins",
											width:"100%",
										}}>
											£{totalPrice}
										</Typography>
									</Grid>
									<Grid item xs={12} alignItems="center" justifyContent="center" textAlign="center">
										<Button variant="outlined" color="primary" margin="auto" 
										sx={{
											width: '90%',
											padding: '5%',
											margin: '10px',
											color: 'white',
											border: '2px solid white',
											borderRadius: '12px',
											textTransform: 'none',
											fontFamily:"Poppins",
											fontWeight: '400',
											'&:hover': {
												border: '2px solid white',
											},
										}}
											onClick={() => {
												navigate('/checkout', { state: { cartList: cartList, totalPrice: totalPrice }, replace: true});
												// Navigate to Checkout
											}}
										>
											Check Out
										</Button> 
									</Grid>
								</Grid>
						</Box>
					</Grid>
				</Grid>
            ) : (
                <Typography variant="h4" >
                    Loading...
                </Typography>
            )}
        </Card>
    );
};

export default Cart;
