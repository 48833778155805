import React from 'react';
import { Button, Grid, Typography  } from '@mui/material';
import { useParams, Link } from 'react-router-dom';
import RingTable from './RingTable';

const Info = () => {
	const { infoId } = useParams();

	function handleInfoChange(infoId) {
		if (infoId === 'delivery') {
			return (
				<div style={{ width: '80%', height: '100%' }}>
					<span style={{color: 'white', fontSize: 20, fontFamily: 'HelveticaNeue', fontWeight: '400', wordWrap: 'break-word'}}>
						Shipping Options:
						<br />
						<br />
						All shipping costs are included in the price for any Silver Riot purchase made. We currently do not provide shipping options outside of the United Kingdom. Delivery time can range from 1 to 6 business days. All orders are shipped through Evri.
						<br />
						<br />
						You will receive a shipping tracking number via email when your order is shipped from our warehouse. Parcel tracking info is available online at Evri.
						<br />
						<br />
						Shipping Restrictions:
						<br />
						<br />
					</span>
					<span style={{color: 'white', fontSize: 20, fontFamily: 'HelveticaNeue', fontWeight: '400', wordWrap: 'break-word'}}>
						<li>We do not deliver to PO Boxes, APO, or FPO addresses.</li>
						<li>Air and ground shipment time frames are in business days only and exclude weekends and/or holidays.</li>
						<br/>
					</span>
					<span style={{color: 'white', fontSize: 20, fontFamily: 'HelveticaNeue', fontWeight: '400', wordWrap: 'break-word'}}>
						Shipping and Item Problems:
						<br/>
						<br/>
						By purchasing from us, the customer agrees that in the event of loss or damage during shipment, the customer will be compensated by Silver Riot and the customer agrees not to perform a credit card chargeback to recover such a loss. The customer also agrees to assume all liability for loss or damage during shipment if there is a 'Signature Release' of any kind on file for the delivery address. All claims for loss or damage during shipment must be initiated within 48 hours of scheduled delivery. If claim is denied by the carrier, the customer understands that Silver Riot is not responsible for reimbursement.
						<br/>
						When your order is received, if there are any noticeable discrepancies with your item, or if the item is different than what was ordered, please contact Silver Riot at: services@silverriot.co.uk within 5 days of receipt of your shipment to make any necessary corrections. Product discrepancies not notified to Silver Riot within 5 days may not be honoured for replacement. Please contact us within 5 days of receipt of your shipment if your item is defective.
						<br/>
						<br/>
						Returns:
						<br/>
						<br/>
						Return shipping charges for items not specifically covered under warranty coverage or otherwise damaged or defective, will be deducted from any refunds given.
						<br/>
						By purchasing any products from Silver Riot you agree to these terms.
					</span>
				</div>
			);
		} else if (infoId === 'returns') {
			return (
				<div style={{width: '80%'}}>
					<span style={{color: 'white', fontSize: 20, fontFamily: 'HelveticaNeue', fontWeight: '400', wordWrap: 'break-word'}}>
						Returns & Exchanges Policy
						<br/>
						<br/>
						If you are not satisfied with your purchase, you may exchange or return it for a refund (including shipping and handling charges) within 30 days from shipping from our warehouse. All purchases, accessories, serial numbered security tags (if provided), and packing materials must be in new condition to avoid refusal of the returned merchandise or restocking fees. All returned or exchanged items must be in new condition, in their original packaging, and must include everything that came with within the box. Any signs of wear or removal of parts will result in refusal of your return.
						<br/>
						<br/>
						If when you receive your purchase, there are any noticeable discrepancies or damages, or if the item is not what was ordered, please contact Silver Riot at:&nbsp;
					</span>
					<span style={{color: 'white', fontSize: 20, fontFamily: 'HelveticaNeue', fontWeight: '400', textDecoration: 'underline', wordWrap: 'break-word'}}>
						services@silverriot.co.uk&nbsp;
					</span>
					<span style={{color: 'white', fontSize: 20, fontFamily: 'HelveticaNeue', fontWeight: '400', wordWrap: 'break-word'}}> 
						&nbsp;within 3 days of receipt of your shipment to make the necessary corrections.
						<br/>
						<br/>
						If a return shipping label is provided via email, please print the label and tape it to the shipping carton. We do not reimburse or refund shipping charges if a third-party shipper is used.
						<br/>
						<br/>
						How to return or exchange an item
						<br/>
						<br/>
						Please contact Silver Riot at:&nbsp;
					</span>
					<span style={{color: 'white', fontSize: 20, fontFamily: 'HelveticaNeue', fontWeight: '400', textDecoration: 'underline', wordWrap: 'break-word'}}>
						services@silverriot.co.uk
					</span>
					<span style={{color: 'white', fontSize: 20, fontFamily: 'HelveticaNeue', fontWeight: '400', wordWrap: 'break-word'}}>
						&nbsp;and with as much information as possible, explain the reasoning behind the return or exchange.
						<br/>
						<br/>
						Place the original package into a shipping carton.
						<br/>
						<br/>
						We recommend you ship via insured ground service with a tracking number. Return shipping charges are the responsibility of the customer. We are not responsible for lost or damaged packages.
					</span>
				</div>
			);
		} else if (infoId === 'privacy') {
			return (
				<div style={{width: '80%'}}>
					<span style={{color: 'white', fontSize: 20, fontFamily: 'HelveticaNeue', fontWeight: '400', wordWrap: 'break-word'}}>
						Privacy Policy for Silver Riot:
						<br/>
						<br/>
						Silver Riot.co.uk (“Silver Riot, “we,” “our,” or “us”) has adopted this privacy policy (“Privacy Policy”) to explain how we collect, store, and use your information. This Privacy Policy applies to our online services via our website at www.silverriot.co.uk. This Privacy Policy does not apply to our information collection activities outside of the Service (unless otherwise stated at the time of collection). If you may have any additional questions or require more information about our Privacy Policy, do not hesitate to contact us.
						<br/>
						<br/>
						By using this website and thus our service, you agree to our Terms of Use and consent to our processing of information as described in this Privacy Policy. If you do not agree and consent, please do not use the website.
						<br/>
						<br/>
						<br/>
						Information Collection:
						<br/>
						<br/>
						Information you provide Silver Riot collects information you provide directly via the website. The data we collect may include data that identifies you personally. The categories of information we have collected in the last 12 months include the following:
						<br/>
						<br/>
					</span>
					<span style={{color: 'white', fontSize: 20, fontFamily: 'HelveticaNeue', fontWeight: '400', wordWrap: 'break-word'}}>
						<li>Contact Data. We collect your first and last name, email address, postal address, telephone number and other similar contact data.</li>
						<li>Payment Data. We collect data necessary to process your payment through Stripe. If you make purchases, data such as your payment instrument, shipping address and transaction numbers will be stored on our Stripe account.</li>
						<li>Content. We collect the content of messages you send to us, such as feedback and product reviews you write, or questions and information you provide for customer support.</li>
					</span>
					<span style={{color: 'white', fontSize: 20, fontFamily: 'HelveticaNeue', fontWeight: '400', wordWrap: 'break-word'}}>
						<br/>
						<br/>
						Silver Riot collects data from you at various points while you are using the Service, including as described below:
						<br/>
					</span>
					<span style={{color: 'white', fontSize: 20, fontFamily: 'HelveticaNeue', fontWeight: '400', textDecoration: 'line-through', wordWrap: 'break-word'}}>
						<br/>
					</span>
					<span style={{color: 'white', fontSize: 20, fontFamily: 'HelveticaNeue', fontWeight: '400', wordWrap: 'break-word'}}>
						<li>Purchasing. Visitors purchasing goods and services through our Service will need to provide valid Payment Data, in addition to Contact Data such as name, mailing address, phone number and email address.</li>
						<li>Customer Service. When you contact customer service you may be asked to provide us with information such as your name, telephone number, address and email. We collect this information as part of our customer service efforts and it is used to confirm your identity, respond to your inquiry or comments, for training purposes, and to assist us in providing better products and services.</li>
					</span>
					<span style={{color: 'white', fontSize: 20, fontFamily: 'HelveticaNeue', fontWeight: '400', wordWrap: 'break-word'}}>
						<br/>
						<br/>
						How we use your information:
						<br/>
						<br/>
						We use the information we collect in various ways, including to:
						<br/>
					</span>
					<span style={{color: 'white', fontSize: 20, fontFamily: 'HelveticaNeue', fontWeight: '400', wordWrap: 'break-word'}}>
						<li>Provide, operate, and maintain our website</li>
						<li>Improve, personalize, and expand our website</li>
						<li>Understand and analyze how you use our website</li>
						<li>Develop new products, services, features, and functionality</li>
						<li>Communicate with you including for customer service, to provide you with updates and other information relating to the website, and for marketing and promotional purposes</li>
						<li>Send you emails</li>
					</span>
					<span style={{color: 'white', fontSize: 20, fontFamily: 'HelveticaNeue', fontWeight: '400', wordWrap: 'break-word'}}>
					<br/>
					Log Files:
					<br/>
					<br/>
					Silver Riot follows a standard procedure of using log files. These files log visitors when they visit websites. All hosting companies do this and a part of hosting services' analytics. The information collected by log files include internet protocol (IP) addresses, browser type, Internet Service Provider (ISP), date and time stamp, referring/exit pages, and possibly the number of clicks. These are not linked to any information that is personally identifiable. The purpose of the information is for analyzing trends, administering the site, tracking users' movement on the website, and gathering demographic information.
					<br/>
					<br/>
					Cookies and Web Beacons:
					<br/>
					</span>
					<span style={{color: 'white', fontSize: 20, fontFamily: 'HelveticaNeue', fontWeight: '400', textDecoration: 'underline', wordWrap: 'break-word'}}>
					<br/>
					</span>
					<span style={{color: 'white', fontSize: 20, fontFamily: 'HelveticaNeue', fontWeight: '400', wordWrap: 'break-word'}}>
					As per the The Data Protection Act 2018 & UK GDPR, a website must ask for its users' 
					</span>
					<span style={{color: 'white', fontSize: 20, fontFamily: 'HelveticaNeue', fontWeight: '400', wordWrap: 'break-word'}}>
					consent to use cookies
					</span>
					<span style={{color: 'white', fontSize: 20, fontFamily: 'HelveticaNeue', fontWeight: '400', wordWrap: 'break-word'}}>
						that are not necessary for accessing the website's functionality. Like any other website, silverriot.co.uk uses “cookies”. We only use necessary cookies which allow the website to function. These will only affect the user's ability to checkout and thus does not collect any information for marketing or advertisement purposes. These cookies are used to store information including visitors' preferences, and the pages on the website that the visitor accessed or visited. The information is used to optimize the users' experience by customizing our web page content based on visitors' browser type and/or other information. Third party cookies that are used on our website are deemed necessary for accessing the website's functionality.
						<br/>
						<br/>
						Our Advertising Partners:
						<br/>
						<br/>
						Some of advertisers on our site may use cookies and web beacons. Our advertising partners are listed below. Each of our advertising partners has their own Privacy Policy for their policies on user data. For easier access, we hyperlinked to their Privacy Policies below:
						<br/>
					</span>
					<span style={{color: 'white', fontSize: 20, fontFamily: 'HelveticaNeue', fontWeight: '400', wordWrap: 'break-word'}}>
						<li>Google - <a style={{color:'white'}} href='https://policies.google.com/technologies/ads'>https://policies.google.com/technologies/ads</a></li>
					</span>
					<span style={{color: 'white', fontSize: 20, fontFamily: 'HelveticaNeue', fontWeight: '400', wordWrap: 'break-word'}}>
								<br/>
								<br/>
								Third Party Privacy Policies:
								<br/>
								<br/>
								silverriot.co.uk's Privacy Policy does not apply to other advertisers or websites. Thus, we are advising you to consult the respective Privacy Policies of these third-party ad servers for more detailed information. It may include their practices and instructions about how to opt-out of certain options. You can choose to disable cookies through your individual browser options. To know more detailed information about cookie management with specific web browsers, it can be found at the browsers' respective websites.
								<br/>
								<br/>
								<br/>
								GDPR Data Protection Rights:
								<br/>
								<br/>
								We would like to make sure you are fully aware of all of your data protection rights. As such, we have linked the full GDPR document below: 
								<br/>
								<br/>
								https://ico.org.uk/for-organisations/data-protection-and-the-eu/data-protection-and-the-eu-in-detail/the-uk-gdpr/
								<br/>
								<br/>
								If you would like to exercise any of these rights, please contact us.
								<br/>
								<br/>
								<br/>
								Children's Information:
								<br/>
								<br/>
								Another part of our priority is adding protection for children while using the internet. We encourage parents and guardians to observe, participate in, and/or monitor and guide their online activity. silverriot.co.uk does not knowingly collect any Personal Identifiable Information from children under the age of 13. If you think that your child provided this kind of information on our website, we strongly encourage you to contact us immediately and we will do our best efforts to promptly remove such information from our records.
								<br/>
								<br/>
								<br/>
								Changes to This Privacy Policy:
								<br/>
								<br/>
								We may update our Privacy Policy from time to time. Thus, we advise you to review this page periodically for any changes. We will notify you of any changes by posting the new Privacy Policy on this page. These changes are effective immediately, after they are posted on this page.
								<br/>
					</span>
				</div>
			)
		} else if (infoId === 'ring-sizing') {
			return (
				<Grid container>
					<Grid item xs={2} sx={{display:'flex', justifyContent:'center', marginBottom:'20px'}}></Grid>
					<Grid item xs={8} sx={{display:'flex', justifyContent:'center', marginBottom:'20px'}}>
						<RingTable />
					</Grid>
					<Grid item xs={2} sx={{display:'flex', justifyContent:'center', marginBottom:'20px'}}></Grid>
					<Grid item xs={12} sx={{display:'flex', justifyContent:'center', marginBottom:'20px', flexDirection:'column', padding: {xl:'0 25%', lg: '0 20%', md: '0 10%'}, }}>
						<Typography variant="h3" sx={{ textAlign: 'left', color: 'white', marginBottom:'20px'}}>
							How to measure your ring size:
						</Typography>
						<Typography variant="h5" sx={{ textAlign: 'left', color: 'white', marginBottom:'20px'}}>
							The best way is to get your fingers sized by a professional jeweller using a ring sizer.  However, we realise that this is not always possible. Here is a quick DIY method of estimating your size:
						</Typography>
						<Typography variant="h5" sx={{ textAlign: 'left', color: 'white'}}>
							Step 1:
						</Typography>
						<Typography variant="h5" sx={{ textAlign: 'left', color: 'white', marginBottom:'20px'}}>
							Grab a piece of string or a strap of paper and wrap it around the base of your desired finger.
						</Typography>
						<Typography variant="h5" sx={{ textAlign: 'left', color: 'white'}}>
							Step 2:
						</Typography>
						<Typography variant="h5" sx={{ textAlign: 'left', color: 'white', marginBottom:'20px'}}>
							With a pen mark the point where the string/paper overlaps with each other.
						</Typography>
						<Typography variant="h5" sx={{ textAlign: 'left', color: 'white'}}>
							Step 3:
						</Typography>
						<Typography variant="h5" sx={{ textAlign: 'left', color: 'white', marginBottom:'20px'}}>
							Unravel the string/paper and with a ruler and measure the length from the start to where your pen mark is. This is the circumference of your finger.
						</Typography>
						<Typography variant="h5" sx={{ textAlign: 'left', color: 'white'}}>
							Step 4:
						</Typography>
						<Typography variant="h5" sx={{ textAlign: 'left', color: 'white'}}>
							Refer to the chart above and determine your ring size.
						</Typography>
					</Grid>
				</Grid>
			)
		}
	};
	const buttonObjList = [
		{"name":"Delivery Policy", "link":"/info/delivery", 'id':'delivery'},
		{"name":"Returns Policy", "link":"/info/returns", 'id':'returns'},
		{"name":"Privacy Policy", "link":"/info/privacy", 'id':'privacy'},
		{"name":"Ring Sizing", "link":"/info/ring-sizing", 'id':'ring-sizing'},
	]
	
	return (

		<Grid container>
			<Grid item xs={12} sx={{ height:'30vw', position:'relative'}}>
				<img src="/images/info_chain.png" alt="hero" style={{width:'100%', height:{md:'300px', lg:'150px'}, objectFit:'cover', transform:'translateY(0%)', zIndex:'-1'}} />
			</Grid>
			<Grid item xs={1} sm={2} md={3} />
			<Grid item container xs={10} sm={8} md={6} sx={{
				display: 'flex',
				alignItems:'center',
				backgroundColor: '#0B0A0A',
				padding:'10px',
				borderRadius: '50px',
				border:'1px solid white',
			}}>
				{buttonObjList.length > 0 ? buttonObjList.map((buttonObj, index) => (
					<Grid item xs={12/buttonObjList.length} display="flex" justifyContent="center" key={index}>
						<Button variant={"outlined"}
							sx={{
								border: infoId === buttonObj.id ? "1px solid white" : "2px solid #0B0A0A",
								borderRadius: '50px',
								color: 'white',
								fontFamily: 'HelveticaNeue',
								'&:hover': {
									border: '2px solid white',
								},
								textAlign: 'center',
							}}
							component={Link}
							to={buttonObj.link}
						>
							{buttonObj.name}
						</Button>
					</Grid>
				)) : <div>No buttons to display</div>}
				{/* <Grid item xs={4} display="flex" justifyContent="center">
					<Button variant={"outlined" }
					sx={{
						border: infoId === 'returns' ? "1px solid white" : "2px solid #0B0A0A" ,
						borderRadius: '50px',
						color: 'white',
						fontFamily:'HelveticaNeue',
						'&:hover': {
							border: '2px solid white',
						},
						textAlign: 'center',
					}}
					component={Link} 
					to='/info/returns'
					>
						Returns Policy
					</Button>
				</Grid>
				<Grid item xs={4} display="flex" justifyContent="center">
					<Button variant={"outlined" }
					sx={{
						border: infoId === 'privacy' ? "1px solid white" : "2px solid #0B0A0A" ,
						borderRadius: '50px',
						color: 'white',
						fontFamily:'HelveticaNeue',
						'&:hover': {
							border: '2px solid white',
						},
						textAlign: 'center',
					}}
					component={Link} 
					to='/info/privacy'
					>
						Privacy Policy
					</Button>
				</Grid> */}
			</Grid>
			<Grid item xs={1} sm={2} md={3} />
			<Grid item xs={12} display="flex" alignItems="center" flexDirection="column" sx={{ marginY:'50px'}}>
				{handleInfoChange(infoId)}
			</Grid>
		</Grid>
	);
};

export default Info;