import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Box, Button, Typography } from '@mui/material';
import TextSnippetIcon from '@mui/icons-material/TextSnippet';
const useStyles = makeStyles((theme) => ({
    draggable: {
        position: 'absolute',
        cursor: 'move',
        userSelect: 'none',
    },
}));

const Draggable = ({ handleDraggable, id }) => {

    const classes = useStyles();
    const [display, setDisplay] = useState('block');
    const [position, setPosition] = useState({ x: (Math.random()*66), y: (Math.random()*59 + 10) });
    const [dragging, setDragging] = useState(false);
    const [offset, setOffset] = useState({ x: 0, y: 0 });
    var screenWidth = window.innerWidth;
    var screenHeight = window.innerHeight;

    const handleMouseDown = (event) => {
        setDragging(true);
        setOffset({
            x: event.clientX/screenWidth*100 - position.x,
            y: event.clientY/screenHeight*100 - position.y,
        });
    };

    const handleMouseMove = (event) => {
        if (dragging) {
            setPosition({
                x: event.clientX/screenWidth*100 - offset.x,
                y: event.clientY/screenHeight*100 - offset.y,
            });
        }
    };
    
    const handleMouseUp = (event) => {
        setDragging(false);
    };
    
    const handleClick = () => {
        
        handleDraggable(id);
        setDisplay('none');
    }
    
    useEffect(() => {
        if (dragging) {
            document.addEventListener('mousemove', handleMouseMove);
            document.addEventListener('mouseup', handleMouseUp);
        } else {
            document.removeEventListener('mousemove', handleMouseMove);
            document.removeEventListener('mouseup', handleMouseUp);
        }

        return () => {
            document.removeEventListener('mousemove', handleMouseMove);
            document.removeEventListener('mouseup', handleMouseUp);
        };
    }, [dragging]);

    return (
        <div
        className={classes.draggable}
            style={{ top: position.y + 'vh', left: position.x + 'vw', display: display}}
            onMouseDown={handleMouseDown}
            onMouseMove={handleMouseMove}
            onMouseUp={handleMouseUp}
        >
            <Box sx={{
                width: '30vw',
                height: '30vh',
                border: '2px solid #ffffff',
                backgroundColor: '#d9d9d9',
                minWidth: '150px',
                maxWidth: '300px',
                minHeight: '200px',
                maxHeight: '400px',
            }}>
                <Box sx={{
                    width:'98%',
                    background: 'linear-gradient(to right, #948F8F, #D9D9D9)',
                    height:'15px',
                    marginX:'1%',
                    marginY:'0.5%',
                    display:'flex',
                    alignItems:'center',
                }}>
                    <TextSnippetIcon sx={{color: '#ffffff', height:'15px', width:'15px'}}/>
                    <Typography variant='h6' sx={{padding:0, margin:0,lineHeight:'10px', whiteSpace:'nowrap', fontSize:'12px', width:'40px', flexGrow:1, overflowX:'hidden', fontWeight:'600', textOverflow: 'ellipsis'}}>SILVER RIOT - Try SR Free! - Microsoft Inte...</Typography>
                    <Box sx={{
                        alignSelf:'flex-end',
                        justifySelf:'flex-end',
                        width:'55px',
                        display:'flex',
                        justifyContent:'flex-end',
                        paddingLeft:'5px',
                        position:'relative',
                    }}>
                        <img src='/images/retro_windows_buttons.png' height='13px' style={{
                            padding:'1px 0'
                        }} alt='retro windows tab icons' />
                        <Button sx={{color: '#ffffff', height:'15px', width:'15px', minWidth:'15px', padding:0, margin:0, zIndex:0, position:'absolute', right:0}} onClick={handleClick}></Button>
                    </Box>
                </Box>
                <Box sx={{
                    width:'calc(98%-3px)',
                    height:'calc(98.5% - 15px - 2px - 1px)',
                    marginX:'1%',
                    marginY:'0.5%',
                    display:'flex',
                    alignItems:'center',
                    justifyContent:'center',
                    borderTop:'2px solid black',
                    borderBottom:'1px solid black',
                    borderLeft:'2px solid black',
                    borderRight:'1px solid black',
                    backgroundColor:'pink',
                }}>
                    <img src='/images/draggable_img.webp' style={{width:'100%', height:'100%'}} alt='decorative products' />
                </Box>
            </Box>
        </div>
    );
};

export default Draggable;