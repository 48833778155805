import React from 'react';
import { Box } from '@mui/material';
import { InstagramEmbed } from "react-social-media-embed";
const CuratedContent = ({ accessibilityState }) => {
	const curatedContentList = [
		{
			link: "https://www.instagram.com/p/C6jqWj4M5Wz/",
		},
		{
			link: "https://www.instagram.com/p/C6jp8S9scu3/",
		},
		{
			link: "https://www.instagram.com/p/C5rIbOysr__/",
		},
		{
			link: "https://www.instagram.com/p/C65rjhXuvLL/"
		}
	];
	const repeatCount = 3;
	let repeatedArray = [];
	for (let i = 0; i < repeatCount; i++) {
		repeatedArray = repeatedArray.concat(curatedContentList);
	}

    return (
		<Box
		sx={{
			overflow: "hidden",
			display:'flex',
			flexDirection:'column',
		}}
		>
			<Box
			sx={{
				backgroundColor: "#0B0A0A",
				display: "flex",
				justifyContent:'flex-start',
				flexDirection: "row",
				width: repeatedArray.length * 23 + "vw",
				// maxWidth: repeatedArray.length * 45 + "vh",
				minWidth:repeatedArray.length*320 + 'px',
				height: "40vw",
				minHeight: '575px',
				animation: "curatedcontent 30s linear infinite",
				"@keyframes curatedcontent": {
				"0%": {
					transform: `translateX(0)`,
				},
				"100%": {
					transform: `translateX(-${100/repeatCount}%)`,
				},
				},
				':hover': {
				animationPlayState: 'paused',
				},
				animationPlayState: accessibilityState ? 'running' : 'paused',
			}}
			>
				{repeatedArray.map((content, index) => (
					<Box key={index} width='23vw' minWidth='320px' paddingY='10px'>
						<InstagramEmbed
						
							url={content.link}
							captioned
							width={'100%'}
							
						/>
					</Box>
				))}
			</Box>
		</Box>
    );

};

export default CuratedContent;